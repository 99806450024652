@use "src/styles/vars.scss" as *;

.app-clients-section {
	//
	.clients-section {
		height: 780px;
		background-color: var(--theme-background-color);
		display: flex;
		justify-content: flex-end;

		&__body {
			position: relative;
			height: 100%;
			width: 100%;
		}

		&__wrapper {
			z-index: 99;
			position: absolute;
			bottom: 250px;
			padding: 0 8% 0;
			width: 50%;
			display: flex;
			flex-direction: column;
			gap: 100px;

			@include max-width($scr-sm) {
				width: 100%;
				top: 40px;
				gap: 60px;
			}
		}

		&__title {
			margin: 0;
			padding: 0 0 10px;
			text-align: left;
			color: var(--c-white);
			text-transform: uppercase;
			font-weight: var(--text-weight-bold);
			font-size: var(--title-font-size);
		}

		&__sub-title {
			margin: 0;
			padding: 0 0 10px;
			color: var(--c-white);
			font-size: var(--title-font-size);
			font-weight: var(--text-weight-normal);
		}

		&__description {
			margin: 0;
			padding: 0 0 20px;
			color: var(--c-white);
			overflow-wrap: break-word;
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-thin);
		}

		&__btn {
			font-weight: var(--text-weight-bold);
			padding: 15px 20px;
			border-radius: 11.92px;

			@include max-width($scr-xc) {
				padding: 10px 20px;
			}
		}

		&__container {
			z-index: 10;
			position: absolute;
			right: 0;
			bottom: 0;
			background-color: var(--c-transparent-black);
			backdrop-filter: blur(10px);
			width: 100%;
			height: 215px;
			display: flex;
			justify-content: flex-end;
		}

		&__slider-container {
			width: calc(92% + 10px);
			height: 100%;
			display: flex;
		}

		&__youtube {
			width: 100%;
			height: 100%;
			aspect-ratio: 16/9;
			overflow: hidden;
			pointer-events: none;
		}

		&__image-wrapper {
			height: 100%;
			position: relative;
		}

		&__image-background {
			width: 100%;
			object-fit: cover;
			height: 100%;
		}

		&__image {
			width: 100%;
			max-width: 50vw;
			height: auto;
			max-height: 80vh;
			position: absolute;
			top: unset;
			bottom: 230px;
			right: 0;

			@include min-width("1055px") {
				bottom: 150px;
			}

			@include min-width($scr-lg) {
				width: unset;
				height: unset;
				max-height: unset;
				top: 0;
				bottom: unset;
			}
		}

		.clients-section__youtube iframe {
			width: 300%;
			height: 100%;
			margin-left: -100%;
			object-fit: cover;
			opacity: 0.5;
		}

		.slider {
			display: flex;
			align-items: center;
			overflow-y: hidden;
			overflow-x: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;
			gap: 60px;
			padding-left: 10px;

			&::-webkit-scrollbar {
				display: none;
			}

			&__item {
				display: flex;
				align-items: center;
				gap: 30px;

				&:not(:last-child) {
					&:after {
						content: "";
						height: 90px;
						width: 1px;
						background: var(--c-white);
					}
				}
			}

			&__image {
				width: 280px;
				height: 155px;
				background-size: cover;
				background-repeat: no-repeat;
				border-radius: 10px;
				flex-shrink: 0;

				&--active {
					border: 2px solid var(--theme-highlight);
					box-shadow: 0 4px 15px 0 rgba(48, 105, 178, 0.8);
				}
			}

			&__wrapper {
				width: 180px;
			}

			&__title {
				margin: 0;
				padding: 0 0 20px;
				color: var(--c-white);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-bold);
				text-transform: uppercase;
			}

			&__description {
				margin: 0;
				padding: 0 0 20px;
				color: var(--c-white);
				overflow-wrap: break-word;
				font-size: var(--text-size-s);
				font-weight: var(--text-weight-regular);
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}

			&__text {
				margin: 0;
				padding: 0 0 20px;
				color: var(--c-white);
				overflow-wrap: break-word;
				font-size: var(--text-size-s);
				font-weight: var(--text-weight-thin);
			}
		}

		.mobile-pagination {
			display: flex;
			justify-content: center;

			.app-pagination {
				border: none;
				background-color: transparent;

				.pagination {
					padding: 0;
				}

				.pagination__container {
					width: 34px;
					height: 34px;
					border-radius: 50%;
					opacity: 80%;
					border: 1px solid rgba(31, 31, 31, 1);
				}

				.pagination__wrapper {
					position: absolute;
					margin: auto 0;
					top: -15px;
					left: 50%;
					transform: translate(-50%, -50%);

					.stepper--active {
						border-radius: 100px;
						background-color: var(--c-white);
						border: none;
					}
				}
			}
		}

		.preview {
			width: 100%;
			height: 120px;
			position: absolute;
			bottom: 0;
			background-color: var(--c-transparent-black);
			backdrop-filter: blur(20px);

			&__container {
				padding: 0 5% 0;
				height: 100%;
				display: flex;
				justify-content: center;
			}

			&__item {
				width: 100%;
				display: flex;
				gap: 10px;
				align-items: center;
				justify-content: space-between;
			}

			&__item-left {
				display: flex;
				align-items: center;
				gap: 15px;
			}

			&__image {
				width: 155px;
				height: 86px;
				background-size: cover;
				background-repeat: no-repeat;
				border-radius: 10px;
				flex-shrink: 0;
				border: 2px solid var(--theme-highlight);
				box-shadow: 0 4px 15px 0 rgba(48, 105, 178, 0.8);
			}

			&__info-wrapper {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}

			&__info-title {
				margin: 0;
				padding: 0;
				color: var(--c-white);
				font-size: var(--text-size-xs);
				font-weight: var(--text-weight-bold);
				text-transform: uppercase;
				line-height: 13px;
			}

			&__info-text {
				margin: 0;
				padding: 0;
				color: var(--c-white);
				overflow-wrap: break-word;
				font-size: var(--text-size-xxs);
				font-weight: var(--text-weight-thin);
			}
		}

		&--mobile {
			//
			.clients-section__wrapper {
				padding: 0 5% 0;
			}

			.clients-section__sub-title {
				padding: 0 0 20px;
				line-height: 38px;
				font-size: var(--title-mobile-font-size);
			}

			.clients-section__description {
				font-size: var(--text-size-s);
			}

			.clients-section__image {
				width: 100%;
				height: unset;
				max-width: unset;
				max-height: unset;
				top: unset;
				right: unset;
				left: 0;
				bottom: 150px;
			}
		}
	}
}
