@use "src/styles/vars.scss" as *;

.page-all-products {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);

	.MuiSelect-icon {
		margin-right: 10px;
	}

	.products {
		height: 100%;
		background-color: var(--theme-background-color);

		.services-section {
			background-color: var(--theme-background-color);
			position: relative;
			@include contentPadding;

			&__title {
				@include titleFont;
				margin: 0;
				padding: 0 0 10px;
				text-align: left;
				color: var(--theme-text-color);
				text-transform: uppercase;
				font-size: calc(var(--text-size-xxxl) - 6px);
				font-weight: var(--text-weight-bold);

				@include max-width($scr-xc) {
					font-size: var(--text-size-xxl);
				}
			}

			&__description {
				max-width: calc(100% - 225px);
				margin: 0;
				color: var(--theme-text-color);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-regular);
				overflow-wrap: break-word;

				@include max-width($scr-sm) {
					max-width: 100%;
				}

				@include max-width($scr-xc) {
					font-size: var(--text-size-s);
				}
			}

			&__header {
				padding-bottom: 60px;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;

				@include max-width($scr-xc) {
					padding-bottom: 40px;
				}
			}

			&__wrapper {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: 20px;
				align-content: flex-start;
			}

			&__button-container {
				margin-bottom: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: var(--text-size-body);

				@include max-width($scr-xc) {
					margin-top: 30px;

					> button {
						width: 112px;
						min-width: 112px;
						padding: 10px 20px;
					}
				}
			}

			&__after {
				padding-bottom: 20px;
				border-bottom: 1px solid var(--c-divider);
			}

			&__pagination {
				display: flex;
				justify-content: center;
				margin-bottom: 30px;
			}
		}

		.services-and-solutions-section {
			padding: 50px 8% 0px;
			background-color: var(--theme-background-color);

			@include max-width($scr-xc) {
				padding: 50px 5% 0px;
			}

			&__title {
				@include titleFont;
				margin: 0;
				padding: 0 0 10px;
				text-align: left;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-bold);

				@include max-width($scr-xc) {
					max-width: 230px;
				}
			}

			&__description {
				max-width: calc(100% - 200px);
				margin: 0;
				color: var(--theme-text-color);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-regular);

				@include max-width($scr-sm) {
					max-width: 100%;
				}
			}

			&__header {
				width: 100%;
				padding-bottom: 60px;
				gap: 40px;
				display: flex;
			}

			&__wrapper {
				display: grid;
				grid-row-gap: 20px;
				grid-auto-rows: 1fr;
				justify-content: space-between;
				grid-template-columns: calc(33.33% - 20px) calc(33.33% - 20px) calc(33.33% - 20px);
			}

			.button-container {
				padding-top: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.view-button {
				padding: 0;
				height: 52px;
				width: 200px;
				gap: 3px;
				border-radius: 8px;
				border: 1px solid var(--c-dark-grey);
				background-color: var(--c-button-black);
				display: flex;
				align-items: center;
				justify-content: center;

				&__text {
					margin: 0;
					color: var(--theme-text-color-2nd);
					font-size: var(--text-size-body);
					font-weight: var(--text-weight-regular);
				}

				&__icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		.service-brochure-section {
			padding-bottom: 30px;
			gap: 20px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@mixin brochureTitle($borderColor) {
				@include titleFont;
				margin: 0;
				text-align: left;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-bold);
				overflow-wrap: break-word;

				&::after {
					content: "";
					display: block;
					height: 4px;
					width: 45%;
					border-radius: 2px;
					background-color: $borderColor;
				}
			}

			@include max-width($scr-xs) {
				flex-direction: column;
			}

			&__box {
				min-width: 285px;
				flex: 0 1 calc(25% - 15px);
				margin-bottom: 0px;

				@include max-width($scr-lg) {
					min-width: 200px;
					margin-bottom: 20px;
					flex: 0 1 calc(33.33% - 13.33px);
				}

				@include max-width($scr-sm) {
					min-width: 100px;
					margin-bottom: 20px;
					flex: 0 1 calc(50% - 10px);
				}

				@include max-width($scr-xx) {
					margin-bottom: 20px;
					flex: 0 1 100%;
				}
			}

			&__service-title {
				margin: 0;
				font-weight: var(--text-weight-bold);
				font-size: calc(var(--text-size-xxxl) - 6px);
				color: var(--c-white);

				@include max-width($scr-xc) {
					font-size: var(--text-size-xxl);
				}
			}

			&__solution-title {
				@include brochureTitle(var(--c-pagination-highlight-2));

				@media (max-width: 412px) {
					max-width: 190px;
				}
			}

			&__description {
				margin: 0;
				color: var(--c-white);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-regular);
				overflow-wrap: break-word;
			}

			&__header {
				padding-bottom: 60px;
				gap: 40px;
				display: flex;
			}

			&__wrapper {
				padding-bottom: 20px;
				gap: 20px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				@include max-width($scr-md) {
					padding-bottom: 10px;
				}
			}

			&__pagination {
				display: flex;
				justify-content: center;
				margin-bottom: 60px;
			}
		}

		.tabs-section {
			@include sectionMarginAndPadding;
			width: 100%;

			@include max-width($scr-xc) {
				margin: 30px 0 30px;
			}
		}

		.tab-section {
			display: flex;
			justify-content: center;
			@include sectionMarginAndPadding;

			@include max-width($scr-xc) {
				margin: 50px 0 30px;
			}
		}
	}
}
